<template>
   <div
      class="rounded-md p-4 flex flex-col sm:flex-row sm:items-center md:flex-col md:items-stretch xl:flex-row xl:items-center gap-3"
      :class="
         outline ?
            `border bg-${color}-50  border-${color}-500`
         :  `bg-${color}-50`
      "
   >
      <div class="flex items-start grow">
         <div class="flex-shrink-0">
            <img
               v-if="type === 'image'"
               src="~/public/image/register/brain.svg"
            />
            <component
               v-else
               :is="selectedIcon"
               :class="`h-5 w-5  text-${color}-500 ${typeof description === 'object' ? 'mt-2' : ''}`"
               aria-hidden="true"
            />
         </div>
         <div class="ml-3">
            <div
               v-if="title"
               :class="`text-sm font-normal text-${titleColor}-500`"
            >
               {{ $t(title) }}
            </div>
            <div
               v-if="description && typeof description === 'string'"
               :class="`mt-2 text-sm text-${descriptionColor}-500`"
            >
               {{ $t(description) }}
            </div>
            <div
               :class="`mt-2 text-sm text-${descriptionColor}-500`"
               v-if="description && typeof description === 'object'"
            >
               <ul role="list" class="list-disc space-y-1 pl-5">
                  <li v-for="item in description" :key="item">
                     {{ $t(item) }}
                  </li>
               </ul>
            </div>
         </div>
      </div>
      <div v-if="$slots.default">
         <slot></slot>
      </div>
   </div>
</template>

<script>
   import {
      ExclamationCircleIcon,
      InformationCircleIcon,
      XCircleIcon,
   } from '@heroicons/vue/24/outline';
   export default {
      components: { XCircleIcon, ExclamationCircleIcon, InformationCircleIcon },
      props: {
         title: {
            type: String,
            default: '',
         },
         description: {
            type: [String, Array],
            default: '',
         },
         type: {
            type: String,
            default: '',
         },
         titleColor: {
            type: String,
            default: 'error',
         },
         descriptionColor: {
            type: String,
            default: 'gray',
         },
         outline: {
            type: Boolean,
            default: false,
         },
         color: {
            type: String,
            default: 'red',
         },
         textColor: {
            type: String,
            default: 'black',
         },
      },
      computed: {
         selectedIcon() {
            if (this.type == 'info') {
               return ExclamationCircleIcon;
            } else if (this.color == 'primary') {
               return ExclamationCircleIcon;
            } else if (this.color == 'secondary') {
               return ExclamationCircleIcon;
            } else {
               return XCircleIcon;
            }
         },
      },
   };
</script>
